import { Controller } from "stimulus"


export default class extends Controller {

  toggle() {
  	var menuItems = $("#menu_items")
  	menuItems.toggleClass("block")
  	menuItems.toggleClass("hidden")
  }
}