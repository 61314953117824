import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {

  connect() {
    StimulusReflex.register(this)
  }

  filter () {
    this.stimulate('DataEntry#filter', event.target.value, event.target.dataset['column'], event.target.dataset['filters'])
  }

}