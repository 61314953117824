import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {

  connect() {
  	//console.log("Yo!")
  	//var selected_row = document.getElementById("selected_data_table")
    //if(selected_row != null) {
  	// selected_row.scrollIntoView({block: "center"})
    //}
    StimulusReflex.register(this)
  }

  hide_subtypes() {
  	var subtype_controls = $("#responder_analysis_subtypes")
  	subtype_controls.addClass("hidden")
  }

  show_subtypes() {
  	var subtype_controls = $("#responder_analysis_subtypes")
  	subtype_controls.removeClass("hidden")
  }
}