import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'


function setSticky() {
  var columnWidths = new Object()
  var col = document.getElementById("sticky-col-1")
  var col2 = document.getElementById("sticky-col-2")
  var col3 = document.getElementById("sticky-col-3")
  var col4 = document.getElementById("sticky-col-4")
  if(col != null && col2 != null && col3 != null && col4 != null) {
    columnWidths['sticky-col-1'] = document.getElementById("sticky-col-1").clientWidth;
    columnWidths['sticky-col-2'] = document.getElementById("sticky-col-2").clientWidth;
    columnWidths['sticky-col-3'] = document.getElementById("sticky-col-3").clientWidth;
    columnWidths['sticky-col-4'] = document.getElementById("sticky-col-4").clientWidth;
    //console.log("1: " + columnWidths['sticky-col-1'])
    //console.log("2: " + columnWidths['sticky-col-2'])
    //console.log("3: " + columnWidths['sticky-col-3'])
    //console.log("4: " + columnWidths['sticky-col-4'])
    var stickyCol1 = document.getElementById("sticky-col-1")
    var stickyCol2 = document.getElementById("sticky-col-2")
    var stickyCol3 = document.getElementById("sticky-col-3")
    var stickyCol4 = document.getElementById("sticky-col-4")
    var stickyCols1 = document.getElementsByClassName("sticky-col-1")
    var stickyCols2 = document.getElementsByClassName("sticky-col-2")
    var stickyCols3 = document.getElementsByClassName("sticky-col-3")
    var stickyCols4 = document.getElementsByClassName("sticky-col-4")
    var index = 0
    var col2Width = columnWidths['sticky-col-1']
    col2Width = col2Width.toString() + "px"
    while (index < stickyCols2.length) { 
        stickyCols2[index].style.left = col2Width
        index++
    }
    index = 0
    var col3Width = columnWidths['sticky-col-1'] + columnWidths['sticky-col-2']
    col3Width = col3Width.toString() + "px"
    while (index < stickyCols3.length) {
        stickyCols3[index].style.left = col3Width
        index++
    }
    index = 0
    var col4Width = columnWidths['sticky-col-1'] + columnWidths['sticky-col-2'] + columnWidths['sticky-col-3']
    col4Width = col4Width.toString() + "px"
    while (index < stickyCols4.length) {
        stickyCols4[index].style.left = col4Width
        index++
    }
  } else {
    console.log("No columns to freeze.")
  }

}

function makeMove(target, columns, dataEntryIds, direction) {
  var id = event.target.id
  var id_array = id.split("-")
  var column = id_array[0]
  var data_entry_id = id_array[1]
  var column_index = null
  var data_entry_index = null
  var index = 0
  //console.log(columns)
  //console.log(dataEntryIds)
  while(index < columns.length) {
    if(columns[index] == column) {
      column_index = index
    }
    index++
  }
  //console.log(column_index)
  var index = 0
  while(index < dataEntryIds.length) {
    if(dataEntryIds[index].toString() == data_entry_id) {
      data_entry_index = index
    }
    index++
  }
  //console.log(data_entry_index)
  if((column_index != null) && (data_entry_index != null)) {
    if(direction == "up") {
      if(data_entry_index != 0) {
        var target_index = data_entry_index - 1
        var target_data_entry_id = dataEntryIds[target_index]
        var target_column = column
        var target_id = target_column + "-" + target_data_entry_id.toString()
        //console.log(target_id)
        document.getElementById(target_id).focus()
      }
    } else if(direction == "down") {
      if(data_entry_index != dataEntryIds.length) {
        var target_index = data_entry_index + 1
        var target_data_entry_id = dataEntryIds[target_index]
        var target_column = column
        var target_id = target_column + "-" + target_data_entry_id.toString()
        //console.log(target_id)
        document.getElementById(target_id).focus()
      }
    } else if(direction == "left") {
      if(column_index != 0) {
        var target_data_entry_id = data_entry_id
        var target_index = column_index - 1
        var target_column = columns[target_index]
        var target_id = target_column + "-" + target_data_entry_id
        //console.log(target_id)
        document.getElementById(target_id).focus()
      }
    } else if(direction == "right") {
      if(column_index != columns.length) {
        var target_data_entry_id = data_entry_id
        var target_index = column_index + 1
        var target_column = columns[target_index]
        var target_id = target_column + "-" + target_data_entry_id
        //console.log(target_id)
        document.getElementById(target_id).focus()
      }
    }
  }
}

function checkMove(target, columns, dataEntryIds, direction) {
  //console.log(target.id)
  var selectedText = document.getSelection().toString()
  //console.log(selectedText)
  if((target.value == null) || (target.value == "")) {
    //console.log("nothing there!")
    //event.target.preventDefault()
    makeMove(target, columns, dataEntryIds, direction)
  } else if(selectedText != "") {
    if(selectedText != null) {
      //console.log("something selected!")
      //event.target.preventDefault()
      makeMove(target, columns, dataEntryIds, direction)
    }
  }
}

export default class extends Controller {

  static targets = [ "type", "columns", "dataentryids" ]

  connect() {
  	//console.log("Yo!")
    var selected_row = document.getElementById("selected_data_table")
    if(selected_row != null) {
     selected_row.scrollIntoView({block: "center"})
    }
    StimulusReflex.register(this)
    setSticky()
    //console.log(this.columnsTarget.innerText)
    //console.log(this.dataentryidsTarget.innerText)
    // can use the columnsTarget and dataentryidsTarget to navigate between different cells in the UI
  }

  beforeReflex(element, reflex, error) {
    //setSticky()
    document.body.classList.add('wait')
    //this.benchmark = performance.now()
  }

  afterReflex(element, reflex, error) {
    setSticky()
    document.body.classList.remove('wait')
    //console.log(reflex, `${(performance.now() - this.benchmark).toFixed(0)}ms`)
  }

  move() {
    if(event.target.tagName != 'SELECT') {
      var columns = JSON.parse(this.columnsTarget.innerText)
      var dataEntries = JSON.parse(this.dataentryidsTarget.innerText)
      if(event.keyCode == "37") {
        //console.log("left!")
        checkMove(event.target, columns, dataEntries, "left")
      }
      if(event.keyCode == "38") {
        //console.log("up!")
        checkMove(event.target, columns, dataEntries, "up")
      }
      if(event.keyCode == "39") {
        //console.log("right!")
        checkMove(event.target, columns, dataEntries, "right")
      }
      if(event.keyCode == "40") {
        //console.log("down!")
        checkMove(event.target, columns, dataEntries, "down")
      }
      if(event.keyCode == "13") {
          event.target.select()
      }
    }
  }

  update() {
    var endpoint_drug_calc = document.getElementById("endpoint_drug_calc")
    if(endpoint_drug_calc != null) {
      if(endpoint_drug_calc.checked == true) {
        endpoint_drug_calc = true
      } else {
        endpoint_drug_calc = false
      }
    } else {
      endpoint_drug_calc = false
    }

    var endpoint_control_calc = document.getElementById("endpoint_control_calc")
    if(endpoint_control_calc != null) {
      if(endpoint_control_calc.checked == true) {
        endpoint_control_calc = true
      } else {
        endpoint_control_calc = false
      }
    } else {
      endpoint_control_calc = false
    }

    var change_from_baseline_drug_calc = document.getElementById("change_from_baseline_drug_calc")
    if(change_from_baseline_drug_calc != null) {
      if(change_from_baseline_drug_calc.checked == true) {
        change_from_baseline_drug_calc = true
      } else {
        change_from_baseline_drug_calc = false
      }
    } else {
      change_from_baseline_drug_calc = false
    }

    var change_from_baseline_control_calc = document.getElementById("change_from_baseline_control_calc")
    if(change_from_baseline_control_calc != null) {
      if(change_from_baseline_control_calc.checked == true) {
        change_from_baseline_control_calc = true
      } else {
        change_from_baseline_control_calc = false
      }
    } else {
      change_from_baseline_control_calc = false
    }

    var effect_calc = document.getElementById("effect_calc")
    if(effect_calc != null) {
      if(effect_calc.checked == true) {
        effect_calc = true
      } else {
        effect_calc = false
      }
    } else {
      effect_calc = false
    }

    var drug_response_rate_calc = document.getElementById("drug_response_rate_calc")
    if(drug_response_rate_calc != null) {
      if(drug_response_rate_calc.checked == true) {
        drug_response_rate_calc = true
      } else {
        drug_response_rate_calc = false
      }
    } else {
      drug_response_rate_calc = false
    }

    var control_response_rate_calc = document.getElementById("control_response_rate_calc")
    if(control_response_rate_calc != null) {
      if(control_response_rate_calc.checked == true) {
        control_response_rate_calc = true
      } else {
        control_response_rate_calc = false
      }
    } else {
      control_response_rate_calc = false
    }

    var drug_control_response_rate_calc = document.getElementById("drug_control_response_rate_calc")
    if(drug_control_response_rate_calc != null) {
      if(drug_control_response_rate_calc.checked == true) {
        drug_control_response_rate_calc = true
      } else {
        drug_control_response_rate_calc = false
      }
    } else {
      drug_control_response_rate_calc = false
    }

    var n_affected_intervention_calc = document.getElementById("n_affected_intervention_calc")
    if(n_affected_intervention_calc != null) {
      if(n_affected_intervention_calc.checked == true) {
        n_affected_intervention_calc = true
      } else {
        n_affected_intervention_calc = false
      }
    } else {
      n_affected_intervention_calc = false
    }

    var n_affected_control_calc = document.getElementById("n_affected_control_calc")
    if(n_affected_control_calc != null) {
      if(n_affected_control_calc.checked == true) {
        n_affected_control_calc = true
      } else {
        n_affected_control_calc = false
      }
    } else {
      n_affected_control_calc = false
    }

    var risk_ratio_calc = document.getElementById("risk_ratio_calc")
    if(risk_ratio_calc != null) {
      if(risk_ratio_calc.checked == true) {
        risk_ratio_calc = true
      } else {
        risk_ratio_calc = false
      }
    } else {
      risk_ratio_calc = false
    }

    var odds_ratio_calc = document.getElementById("odds_ratio_calc")
    if(odds_ratio_calc != null) {
      if(odds_ratio_calc.checked == true) {
        odds_ratio_calc = true
      } else {
        odds_ratio_calc = false
      }
    } else {
      odds_ratio_calc = false
    }

    var previousValue = ""
    if (event.target.tagName == "SELECT") {
      previousValue = event.target.dataset['selectedvalue']
    } else {
      previousValue = event.target.defaultValue
    }
    //console.log("Yo!")
    //console.log(event.target.value)
    //console.log(event.target.dataset['elementid'])
    //console.log(event.target.dataset['column'])
    //console.log(event.target.dataset['currentuserid'])
    if(this.typeTarget.value == "Data Entry") {
      if(event.target.value != previousValue) {        
        event.target.classList.remove("certified")
        event.target.classList.remove("discuss")
        event.target.classList.add("saved")
        this.stimulate('DataEntry#update', event.target.dataset['elementid'], event.target.dataset['column'], event.target.value, event.target.dataset['currentuserid'])
      } else {
        if(event.target.classList.contains("discuss")) {
          event.target.classList.remove("discuss")
        }
        if(event.target.classList.contains("calculated")) {
          //if the value is calculated and the value is unchanged, leave it be.
        } else {
          event.target.classList.add("saved")
          //console.log(event.target.value)
          this.stimulate('DataEntry#update', event.target.dataset['elementid'], event.target.dataset['column'], event.target.value, event.target.dataset['currentuserid'])
          //console.log("no change!")
        }

      }
      //calculations
      var error_codes = ["NC", "NL", "NN", "NR", "NA", "Not Estimable"]
      var id = event.target.id
      var id_array = id.split("-")
      var column = id_array[0]
      var data_entry_id = id_array[1]
      if(event.target.value != "" && error_codes.includes(event.target.value) == false) {
        if(event.target.value != null) {
          var last_char = event.target.value.substr(event.target.value.length - 1)
          //baseline drug entered calculations
          if(column == "baseline_drug") {
            var endpoint_drug_id = "endpoint_drug-" + data_entry_id
            var endpoint_drug = null
            if(document.getElementById(endpoint_drug_id) != null) {
              endpoint_drug = document.getElementById(endpoint_drug_id).value
            } else {
              console.log("endpoint drug not found! column: baseline drug")
            }
            if(endpoint_drug != "" && error_codes.includes(endpoint_drug) == false) {
              if(endpoint_drug != null) {
                var change_from_baseline_drug_id = "change_from_baseline_drug-" + data_entry_id
                var change_from_baseline_drug = document.getElementById(change_from_baseline_drug_id).value
                var value = parseFloat(event.target.value) - parseFloat(endpoint_drug)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var change_from_baseline_drug = document.getElementById(change_from_baseline_drug_id)
                if(change_from_baseline_drug_calc == true) {
                  change_from_baseline_drug.value = value
                  this.stimulate('DataEntry#calculate', change_from_baseline_drug.dataset['elementid'], change_from_baseline_drug.dataset['column'], change_from_baseline_drug.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var change_from_baseline_drug_id = "change_from_baseline_drug-" + data_entry_id
            var change_from_baseline_drug = null
            if(document.getElementById(change_from_baseline_drug_id) != null) {
              change_from_baseline_drug = document.getElementById(change_from_baseline_drug_id).value
            } else {
              console.log("change from baseline drug not found! column: baseline drug")
            }
            if(change_from_baseline_drug != "" && error_codes.includes(change_from_baseline_drug) == false) {
              if(change_from_baseline_drug != null) {
                var endpoint_drug_id = "endpoint_drug-" + data_entry_id
                var endpoint_drug = document.getElementById(endpoint_drug_id).value
                var value = parseFloat(event.target.value) + parseFloat(change_from_baseline_drug)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(endpoint_drug_id)
                if(endpoint_drug_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
          // baseline control data entered calculations
          } else if(column == "baseline_control") {
            var endpoint_control_id = "endpoint_control-" + data_entry_id
            var endpoint_control = null
            if(document.getElementById(endpoint_control_id) != null) {
              endpoint_control = document.getElementById(endpoint_control_id).value
            } else {
              console.log("endpoint control not found! column: baseline control")
            }
            if(endpoint_control != "" && error_codes.includes(endpoint_control) == false) {
              if(endpoint_control != null) {
                var change_from_baseline_control_id = "change_from_baseline_control-" + data_entry_id
                var change_from_baseline_control = document.getElementById(change_from_baseline_control_id).value
                var value = parseFloat(event.target.value) - parseFloat(endpoint_control)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(change_from_baseline_control_id)
                if(change_from_baseline_control_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var change_from_baseline_control_id = "change_from_baseline_control-" + data_entry_id
            var change_from_baseline_control = null
            if(document.getElementById(change_from_baseline_control_id) != null) {
              change_from_baseline_control = document.getElementById(change_from_baseline_control_id).value
            } else {
              console.log("change from baseline control not found! column: baseline control")
            }
            if(change_from_baseline_control != "" && error_codes.includes(change_from_baseline_control) == false) {
              if(change_from_baseline_control != null) {
                var endpoint_control_id = "endpoint_control-" + data_entry_id
                var endpoint_control = document.getElementById(endpoint_control_id).value
                var value = parseFloat(event.target.value) + parseFloat(change_from_baseline_control)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(endpoint_control_id)
                if(endpoint_control_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
          // endpoint drug data entered calculations
          } else if(column == "endpoint_drug") {
            var baseline_drug_id = "baseline_drug-" + data_entry_id
            var baseline_drug = null
            if(document.getElementById(baseline_drug_id) != null) {
              baseline_drug = document.getElementById(baseline_drug_id).value
            } else {
              console.log("baseline drug not found! column: endpoint drug")
            }
            if(baseline_drug != "" && error_codes.includes(baseline_drug) == false) {
              if(baseline_drug != null) {
                var change_from_baseline_drug_id = "change_from_baseline_drug-" + data_entry_id
                var change_from_baseline_drug = document.getElementById(change_from_baseline_drug_id).value
                var value = parseFloat(baseline_drug) - parseFloat(event.target.value)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(change_from_baseline_drug_id)
                if(change_from_baseline_drug_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
          // endpoint control data entered calculations
          } else if (column == "endpoint_control") {
            var baseline_control_id = "baseline_control-" + data_entry_id
            var baseline_control = null
            if(document.getElementById(baseline_control_id) != null) {
              baseline_control = document.getElementById(baseline_control_id).value
            } else {
              console.log("baseline control not found! column: endpoint control")
            }
            if(baseline_control != "" && error_codes.includes(baseline_control) == false) {
              if(baseline_control != null) {
                var change_from_baseline_control_id = "change_from_baseline_control-" + data_entry_id
                var change_from_baseline_control = document.getElementById(change_from_baseline_control_id).value
                var value = parseFloat(baseline_control) - parseFloat(event.target.value)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(change_from_baseline_control_id)
                if(change_from_baseline_control_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
          // change from baseline drug data entered calculations
          } else if (column == "change_from_baseline_drug") {
            var baseline_drug_id = "baseline_drug-" + data_entry_id
            var baseline_drug = null
            if(document.getElementById(baseline_drug_id) != null) {
              baseline_drug = document.getElementById(baseline_drug_id).value
            } else {
              console.log("baseline drug not found! column: change from baseline drug")
            }
            if(baseline_drug != "" && error_codes.includes(baseline_drug) == false) {
              if(baseline_drug != null) {
                var endpoint_drug_id = "endpoint_drug-" + data_entry_id
                var endpoint_drug = document.getElementById(endpoint_drug_id).value
                var value = parseFloat(baseline_drug) + parseFloat(event.target.value)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(endpoint_drug_id)
                if(endpoint_drug_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var change_from_baseline_control_id = "change_from_baseline_control-" + data_entry_id
            var change_from_baseline_control = null
            if(document.getElementById(change_from_baseline_control_id) != null) {
              change_from_baseline_control = document.getElementById(change_from_baseline_control_id).value
            } else {
              console.log("change from baseline control not found! column: change from baseline drug")
            }
            if(change_from_baseline_control != "" && error_codes.includes(change_from_baseline_control) == false) {
              if(change_from_baseline_control != null) {    
                var effect_size_id = "effect_size-" + data_entry_id
                var effect_size = document.getElementById(effect_size_id).value
                var value = parseFloat(event.target.value) - parseFloat(change_from_baseline_control)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(effect_size_id)
                if(effect_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
          // change from baseline control data entered calculations  
          } else if (column == "change_from_baseline_control") {
            var baseline_control_id = "baseline_control-" + data_entry_id
            var baseline_control = null
            if(document.getElementById(baseline_control_id) != null) {
              baseline_control = document.getElementById(baseline_control_id).value
            } else {
              console.log("baseline control not found! column: change from baseline control")
            }
            if(baseline_control != "" && error_codes.includes(baseline_control) == false) {
              if(baseline_control != null) {
                var endpoint_control_id = "endpoint_control-" + data_entry_id
                var endpoint_control = document.getElementById(endpoint_control_id).value
                var value = parseFloat(event.target.value) + parseFloat(baseline_control)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(endpoint_control_id)
                if(endpoint_control_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var change_from_baseline_drug_id = "change_from_baseline_drug-" + data_entry_id
            var change_from_baseline_drug = null
            if(document.getElementById(change_from_baseline_drug_id) != null) {
              change_from_baseline_drug = document.getElementById(change_from_baseline_drug_id).value
            } else {
              console.log("change from baseline drug not found! column: change from baseline control")
            }
            if(change_from_baseline_drug != "" && error_codes.includes(change_from_baseline_drug) == false) {
              if(change_from_baseline_drug != null) {
                var effect_size_id = "effect_size-" + data_entry_id
                var effect_size = document.getElementById(effect_size_id).value
                var value = parseFloat(change_from_baseline_drug) - parseFloat(event.target.value)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(effect_size_id)
                if(effect_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
          // drug response rate data entered calculations
          } else if(column == "drug_response_rate") {
            var control_response_rate_id = "control_response_rate-" + data_entry_id
            var control_response_rate = null
            if(document.getElementById(control_response_rate_id) != null) {
              control_response_rate = document.getElementById(control_response_rate_id).value
            } else {
              console.log("control response rate not found! column: drug response rate")
            }
            if(control_response_rate != "" && error_codes.includes(control_response_rate) == false) {
              if(control_response_rate != null) {
                var drug_control_response_rate_id = "drug_control_response_rate-" + data_entry_id
                var drug_control_response_rate = document.getElementById(drug_control_response_rate_id).value
                var value = parseFloat(event.target.value) - parseFloat(control_response_rate)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(drug_control_response_rate_id)
                if(drug_control_response_rate_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var n_total_intervention_id = "n_total_intervention-" + data_entry_id
            var n_total_intervention = null
            if(document.getElementById(n_total_intervention_id) != null) {
              n_total_intervention = document.getElementById(n_total_intervention_id).value
            } else {
              console.log("n total intervention not found! column: drug response rate")
            }
            if(n_total_intervention != "" && error_codes.includes(n_total_intervention) == false) {
              if(n_total_intervention != null) {
                var n_affected_intervention_id = "n_affected_intervention-" + data_entry_id
                var n_affected_intervention = document.getElementById(n_affected_intervention_id).value
                var value = parseFloat(event.target.value) / 100
                value = value * parseFloat(n_total_intervention)
                value = value.toFixed()
                var target = document.getElementById(n_affected_intervention_id)
                if(n_affected_intervention_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
          } else if(column == "control_response_rate") {
            var drug_response_rate_id = "drug_response_rate-" + data_entry_id
            var drug_response_rate = null
            if(document.getElementById(drug_response_rate_id) != null) {
              drug_response_rate = document.getElementById(drug_response_rate_id).value
            } else {
              console.log("drug response rate not found! column: control response rate")
              //console.log(data_entry_id)
              //console.log(drug_response_rate_id)
            }
            if(drug_response_rate != "" && error_codes.includes(drug_response_rate) == false) {
              if(drug_response_rate != null) {
                var drug_control_response_rate_id = "drug_control_response_rate-" + data_entry_id
                var drug_control_response_rate = document.getElementById(drug_control_response_rate_id).value
                var value = parseFloat(drug_response_rate) - parseFloat(event.target.value)
                value = value.toFixed(5)
                if(last_char == "%") {
                  value = value + "%"
                }
                var target = document.getElementById(drug_control_response_rate_id)
                if(drug_control_response_rate_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var n_total_control_id = "n_total_control-" + data_entry_id
            var n_total_control = null
            if(document.getElementById(n_total_control_id) != null) {
              n_total_control = document.getElementById(n_total_control_id).value
            } else {
              console.log("n total control not found! column: control response rate")
            }
            if(n_total_control != "" && error_codes.includes(n_total_control) == false) {
              if(n_total_control != null) {
                var n_affected_control_id = "n_affected_control-" + data_entry_id
                var n_affected_control = document.getElementById(n_affected_control_id).value
                var value = parseFloat(event.target.value) / 100
                value = value * parseFloat(n_total_control)
                value = value.toFixed()
                var target = document.getElementById(n_affected_control_id)
                if(n_affected_control_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
          } else if(column == "n_affected_intervention") {
            var n_total_intervention_id = "n_total_intervention-" + data_entry_id
            var n_total_intervention = null
            if(document.getElementById(n_total_intervention_id) != null) {
              n_total_intervention = document.getElementById(n_total_intervention_id).value
            } else {
              console.log("n total intervention not found! column: n affected intervention")
            }
            if(n_total_intervention != "" && error_codes.includes(n_total_intervention) == false) {
              if(n_total_intervention != null) {
                var drug_response_rate_id = "drug_response_rate-" + data_entry_id
                var drug_response_rate = document.getElementById(drug_response_rate_id).value
                var value = parseFloat(event.target.value) / parseFloat(n_total_intervention)
                value = value * 100
                value = value.toFixed(5)
                value = value + "%"
                var target = document.getElementById(drug_response_rate_id)
                if(drug_response_rate_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var n_total_intervention_id = "n_total_intervention-" + data_entry_id
            var n_total_intervention = null
            if(document.getElementById(n_total_intervention_id) != null) {
              n_total_intervention = document.getElementById(n_total_intervention_id).value
            } else {
              console.log("n total intervention not found! column: n affected intervention")
            }
            if(n_total_intervention != "" && error_codes.includes(n_total_intervention) == false) {
              if(n_total_intervention != null) {
                var n_affected_control_id = "n_affected_control-" + data_entry_id
                var n_affected_control = null
                if(document.getElementById(n_affected_control_id) != null) {
                  n_affected_control = document.getElementById(n_affected_control_id).value
                } else {
                  console.log("n affected control not found! column: n affected intervention")
                }
                if(n_affected_control != "" && error_codes.includes(n_affected_control) == false) {
                  if(n_affected_control != null) {
                    var n_total_control_id = "n_total_control-" + data_entry_id
                    var n_total_control = null
                    if(document.getElementById(n_total_control_id) != null) {
                      n_total_control = document.getElementById(n_total_control_id).value
                    } else {
                      console.log("n total control not found! column: n affected intervention")
                    }
                    if(n_total_control != "" && error_codes.includes(n_total_control) == false) {
                      if(n_total_control != null) {
                        var risk_ratio_id = "risk_ratio-" + data_entry_id
                        var risk_ratio = document.getElementById(risk_ratio_id).value
                        var value = parseFloat(event.target.value) / parseFloat(n_total_intervention)
                        var value2 = parseFloat(n_affected_control) / parseFloat(n_total_control)
                        value = value / value2
                        value = value.toFixed(5)
                        var target = document.getElementById(risk_ratio_id)
                        if(risk_ratio_calc == true) {
                          target.value = value
                          this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                        }
                      }
                    }
                  }
                }
              }
            }
            var n_total_intervention_id = "n_total_intervention-" + data_entry_id
            var n_total_intervention = null
            if(document.getElementById(n_total_intervention_id) != null) {
              n_total_intervention = document.getElementById(n_total_intervention_id).value
            } else {
              console.log("n total intervention not found! column: n affected intervention")
            }
            if(n_total_intervention != "" && error_codes.includes(n_total_intervention) == false) {
              if(n_total_intervention != null) {
                var n_affected_control_id = "n_affected_control-" + data_entry_id
                var n_affected_control = null
                if(document.getElementById(n_affected_control_id) != null) {
                  n_affected_control = document.getElementById(n_affected_control_id).value
                } else {
                  console.log("n affected control not found! column: n affected intervention")
                }
                if(n_affected_control != "" && error_codes.includes(n_affected_control) == false) {
                  if(n_affected_control != null) {
                    var n_total_control_id = "n_total_control-" + data_entry_id
                    var n_total_control = null
                    if(document.getElementById(n_total_control_id) != null) {
                      n_total_control = document.getElementById(n_total_control_id).value
                    } else {
                      console.log("n total control not found! column: n affected intervention")
                    }
                    if(n_total_control != "" && error_codes.includes(n_total_control) == false) {
                      if(n_total_control != null) {
                        var odds_ratio_id = "odds_ratio-" + data_entry_id
                        var odds_ratio = document.getElementById(odds_ratio_id).value
                        var value = parseFloat(event.target.value) / (parseFloat(n_total_intervention) - parseFloat(event.target.value))
                        var value2 = parseFloat(n_affected_control) / (parseFloat(n_total_control) - parseFloat(n_affected_control))
                        value = value / value2
                        value = value.toFixed(5)
                        var target = document.getElementById(odds_ratio_id)
                        if(odds_ratio_calc == true) {
                          target.value = value
                          this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                        }
                      }
                    }
                  }
                }
              }
            }
          } else if(column == "n_total_intervention") {
            var drug_response_rate_id = "drug_response_rate-" + data_entry_id
            //console.log(drug_response_rate_id)
            var drug_response_rate = null
            if (document.getElementById(drug_response_rate_id) != null) {
              drug_response_rate = document.getElementById(drug_response_rate_id).value
            } else {
              console.log("drug response rate not found! column: n total intervention")
            }
            if(drug_response_rate != "" && error_codes.includes(drug_response_rate) == false) {
              if(drug_response_rate != null) {
                var n_affected_intervention_id = "n_affected_intervention-" + data_entry_id
                var n_affected_intervention = document.getElementById(n_affected_intervention_id).value
                var value = parseFloat(drug_response_rate) / 100
                value = value * parseFloat(event.target.value)
                value = value.toFixed()
                var target = document.getElementById(n_affected_intervention_id)
                if(n_affected_intervention_calc == true) {
                  target.value = value
                  //console.log(target.dataset['elementid'])
                  //console.log(target.dataset['column'])
                  //console.log(target.value)
                  //console.log(event.target.dataset['currentuserid'])
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var n_affected_intervention_id = "n_affected_intervention-" + data_entry_id
            var n_affected_intervention = null
            if (document.getElementById(n_affected_intervention_id) != null) {
              n_affected_intervention = document.getElementById(n_affected_intervention_id).value
            } else {
              console.log("n affected intervention not found! column: n total intervention")
            }
            if(n_affected_intervention != "" && error_codes.includes(n_affected_intervention) == false) {
              if(n_affected_intervention != null) {
                var drug_response_rate_id = "drug_response_rate-" + data_entry_id
                var drug_response_rate = document.getElementById(drug_response_rate_id).value
                var value = parseFloat(n_affected_intervention) / parseFloat(event.target.value)
                value = value * 100
                value = value.toFixed(5)
                value = value + "%"
                var target = document.getElementById(drug_response_rate_id)
                if(drug_response_rate_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var n_affected_intervention_id = "n_affected_intervention-" + data_entry_id
            var n_affected_intervention = null
            if (document.getElementById(n_affected_intervention_id) != null) {
              n_affected_intervention = document.getElementById(n_affected_intervention_id).value
            } else {
              console.log("n affected intervention not found! column: n total intervention")
            }
            if(n_affected_intervention != "" && error_codes.includes(n_affected_intervention) == false) {
              if(n_affected_intervention != null) {
                var n_affected_control_id = "n_affected_control-" + data_entry_id
                var n_affected_control = null
                if (document.getElementById(n_affected_control_id) != null) {
                  n_affected_control = document.getElementById(n_affected_control_id).value
                } else {
                  console.log("n affected control not found! column: n total intervention")
                }
                if(n_affected_control != "" && error_codes.includes(n_affected_control) == false) {
                  if(n_affected_control != null) {
                    var n_total_control_id = "n_total_control-" + data_entry_id
                    var n_total_control = null
                    if (document.getElementById(n_total_control_id) != null) {
                      n_total_control = document.getElementById(n_total_control_id).value
                    } else {
                      console.log("n total control not found! column: n total intervention")
                    }
                    if(n_total_control != "" && error_codes.includes(n_total_control) == false) {
                      if(n_total_control != null) {
                        var risk_ratio_id = "risk_ratio-" + data_entry_id
                        var risk_ratio = document.getElementById(risk_ratio_id).value
                        var value = parseFloat(n_affected_intervention) / parseFloat(event.target.value)
                        var value2 = parseFloat(n_affected_control) / parseFloat(n_total_control)
                        value = value / value2
                        value = value.toFixed(5)
                        var target = document.getElementById(risk_ratio_id)
                        if(risk_ratio_calc == true) {
                          target.value = value
                          this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                        }
                      }
                    }
                  }
                }
              }
            }
            var n_affected_intervention_id = "n_affected_intervention-" + data_entry_id
            var n_affected_intervention = null
            if(document.getElementById(n_affected_intervention_id) != null ) {
              n_affected_intervention = document.getElementById(n_affected_intervention_id).value
            } else {
              console.log("n affected intervention not found! column: n total intervention")
            }
            if(n_affected_intervention != "" && error_codes.includes(n_affected_intervention) == false) {
              if(n_affected_intervention != null) {
                var n_affected_control_id = "n_affected_control-" + data_entry_id
                var n_affected_control = null
                if(document.getElementById(n_affected_control_id) != null ) {
                  n_affected_control = document.getElementById(n_affected_control_id).value
                } else {
                  console.log("n affected control not found! column: n total intervention")
                }
                if(n_affected_control != "" && error_codes.includes(n_affected_control) == false) {
                  if(n_affected_control != null) {
                    var n_total_control_id = "n_total_control-" + data_entry_id
                    var n_total_control = null
                    if(document.getElementById(n_total_control_id) != null) {
                      n_total_control = document.getElementById(n_total_control_id).value
                    } else {
                      console.log("n total control not found! column: n total intervention")
                    }
                    if(n_total_control != "" && error_codes.includes(n_total_control) == false) {
                      if(n_total_control != null) {
                        var odds_ratio_id = "odds_ratio-" + data_entry_id
                        var odds_ratio = document.getElementById(odds_ratio_id).value
                        var value = parseFloat(n_affected_intervention) / (parseFloat(event.target.value) - parseFloat(n_affected_intervention))
                        var value2 = parseFloat(n_affected_control) / (parseFloat(n_total_control) - parseFloat(n_affected_control))
                        value = value / value2
                        value = value.toFixed(5)
                        var target = document.getElementById(odds_ratio_id)
                        if(odds_ratio_calc == true) {
                          target.value = value
                          this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                        }
                      }
                    }
                  }
                }
              }
            }
          } else if(column == "n_affected_control") {
            var n_total_control_id = "n_total_control-" + data_entry_id
            var n_total_control = null
            if(document.getElementById(n_total_control_id) != null) {
              n_total_control = document.getElementById(n_total_control_id).value
            } else {
              console.log("n total control not found! column: n affected control")
            }
            if(n_total_control != "" && error_codes.includes(n_total_control) == false) {
              if(n_total_control != null) {
                var control_response_rate_id = "control_response_rate-" + data_entry_id
                var control_response_rate = document.getElementById(control_response_rate_id).value
                var value = parseFloat(event.target.value) / parseFloat(n_total_control)
                value = value * 100
                value = value.toFixed(5)
                value = value + "%"
                var target = document.getElementById(control_response_rate_id)
                if(control_response_rate_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var n_affected_intervention_id = "n_affected_intervention-" + data_entry_id
            var n_affected_intervention = null
            if(document.getElementById(n_affected_intervention_id) != null) {
              n_affected_intervention = document.getElementById(n_affected_intervention_id).value
            } else {
              console.log("n affected intervention not found! column: n affected control")
            }
            if(n_affected_intervention != "" && error_codes.includes(n_affected_intervention) == false) {
              if(n_affected_intervention != null) {
                var n_total_intervention_id = "n_total_intervention-" + data_entry_id
                var n_total_intervention = null
                if(document.getElementById(n_total_intervention_id) != null ) {
                  n_total_intervention = document.getElementById(n_total_intervention_id).value
                } else {
                  console.log("n total intervention not found! column: n affected control")
                }
                if(n_total_intervention != "" && error_codes.includes(n_total_intervention) == false) {
                  if(n_total_intervention != null) {
                    var n_total_control_id = "n_total_control-" + data_entry_id
                    var n_total_control = null
                    if(document.getElementById(n_total_control_id) != null ) {
                      n_total_control = document.getElementById(n_total_control_id).value
                    } else {
                      console.log("n total control not found! column: n affected control")
                    }
                    if(n_total_control != "" && error_codes.includes(n_total_control) == false) {
                      if(n_total_control != null) {
                        var risk_ratio_id = "risk_ratio-" + data_entry_id
                        var risk_ratio = document.getElementById(risk_ratio_id).value
                        var value = parseFloat(n_affected_intervention) / parseFloat(n_total_intervention)
                        var value2 = parseFloat(event.target.value) / parseFloat(n_total_control)
                        value = value / value2
                        value = value.toFixed(5)
                        var target = document.getElementById(risk_ratio_id)
                        if(risk_ratio_calc == true) {
                          target.value = value
                          this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                        }
                      }
                    }
                  }
                }
              }
            }
            var n_affected_intervention_id = "n_affected_intervention-" + data_entry_id
            var n_affected_intervention = null
            if(document.getElementById(n_affected_intervention_id) != null) {
              n_affected_intervention = document.getElementById(n_affected_intervention_id).value
            } else {
              console.log("n affected intervention not found! column: n affected control")
            }
            if(n_affected_intervention != "" && error_codes.includes(n_affected_intervention) == false) {
              if(n_affected_intervention != null) {
                var n_total_intervention_id = "n_total_intervention-" + data_entry_id
                var n_total_intervention = null
                if(document.getElementById(n_total_intervention_id) != null) {
                  n_total_intervention = document.getElementById(n_total_intervention_id).value
                } else {
                  console.log("n total intervention not found! column: n affected control")
                }
                if(n_total_intervention != "" && error_codes.includes(n_total_intervention) == false) {
                  if(n_total_intervention != null) {
                    var n_total_control_id = "n_total_control-" + data_entry_id
                    var n_total_control = null
                    if(document.getElementById(n_total_control_id) != null) {
                      n_total_control = document.getElementById(n_total_control_id).value
                    } else {
                      console.log("n total control not found! column: n affected control")
                    }
                    if(n_total_control != "" && error_codes.includes(n_total_control) == false) {
                      if(n_total_control != null) {
                        var odds_ratio_id = "odds_ratio-" + data_entry_id
                        var odds_ratio = document.getElementById(odds_ratio_id).value
                        var value = parseFloat(n_affected_intervention) / (parseFloat(n_total_intervention) - parseFloat(n_affected_intervention))
                        var value2 = parseFloat(event.target.value) / (parseFloat(n_total_control) - parseFloat(event.target.value))
                        value = value / value2
                        value = value.toFixed(5)
                        var target = document.getElementById(odds_ratio_id)
                        if(odds_ratio_calc == true) {
                          target.value = value
                          this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                        }
                      }
                    }
                  }
                }
              }
            }
          } else if(column == "n_total_control") {
            var n_affected_control_id = "n_affected_control-" + data_entry_id
            var n_affected_control = null
            if(document.getElementById(n_affected_control_id) != null) {
              n_affected_control = document.getElementById(n_affected_control_id).value
            } else {
              console.log("n affected control not found! column: n total control")
            }
            if(n_affected_control != "" && error_codes.includes(n_affected_control) == false) {
              if(n_affected_control != null) {
                var control_response_rate_id = "control_response_rate-" + data_entry_id
                var control_response_rate = document.getElementById(control_response_rate_id).value
                var value = parseFloat(n_affected_control) / parseFloat(event.target.value)
                value = value * 100
                value = value.toFixed(5)
                value = value + "%"
                var target = document.getElementById(control_response_rate_id)
                if(control_response_rate_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var control_response_rate_id = "control_response_rate-" + data_entry_id
            var control_response_rate = null
            if(document.getElementById(control_response_rate_id) != null) {
              control_response_rate = document.getElementById(control_response_rate_id).value
            } else {
              console.log("control response rate not found! column: n total control")
            }
            if(control_response_rate != "" && error_codes.includes(control_response_rate) == false) {
              if(control_response_rate != null) {
                var n_affected_control_id = "n_affected_control-" + data_entry_id
                var n_affected_control = document.getElementById(n_affected_control_id).value
                var value = parseFloat(control_response_rate) / 100
                value = value * parseFloat(event.target.value)
                value = value.toFixed()
                var target = document.getElementById(n_affected_control_id)
                if(n_affected_control_calc == true) {
                  target.value = value
                  this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                }
              }
            }
            var n_affected_intervention_id = "n_affected_intervention-" + data_entry_id
            var n_affected_intervention = null
            if(document.getElementById(n_affected_intervention_id) != null ) {
              n_affected_intervention = document.getElementById(n_affected_intervention_id).value
            } else {
              console.log("n affected intervention not found! column: n total control")
            }
            if(n_affected_intervention != "" && error_codes.includes(n_affected_intervention) == false) {
              if(n_affected_intervention != null) {
                var n_total_intervention_id = "n_total_intervention-" + data_entry_id
                var n_total_intervention = null
                if(document.getElementById(n_total_intervention_id) != null) {
                  n_total_intervention = document.getElementById(n_total_intervention_id).value
                } else {
                  console.log("n total intervention not found! column: n total control")
                }
                if(n_total_intervention != "" && error_codes.includes(n_total_intervention) == false) {
                  if(n_total_intervention != null) {
                    var n_affected_control_id = "n_affected_control-" + data_entry_id
                    var n_affected_control = null
                    if(document.getElementById(n_affected_control_id) != null) {
                      n_affected_control = document.getElementById(n_affected_control_id).value
                    } else {
                      console.log("n affected control not found! column: n total control")
                    }
                    if(n_affected_control != "" && error_codes.includes(n_affected_control) == false) {
                      if(n_affected_control != null) {
                        var risk_ratio_id = "risk_ratio-" + data_entry_id
                        var risk_ratio = document.getElementById(risk_ratio_id).value
                        var value = parseFloat(n_affected_intervention) / parseFloat(n_total_intervention)
                        var value2 = parseFloat(n_affected_control) / parseFloat(event.target.value)
                        value = value / value2
                        value = value.toFixed(5)
                        var target = document.getElementById(risk_ratio_id)
                        if(risk_ratio_calc == true) {
                          target.value = value
                          this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                        }
                      }
                    }
                  }
                }
              }
            }
            var n_affected_intervention_id = "n_affected_intervention-" + data_entry_id
            var n_affected_intervention = null
            if(document.getElementById(n_affected_intervention_id) != null) {
              n_affected_intervention = document.getElementById(n_affected_intervention_id).value
            } else {
              console.log("n affected intervention not found! column: n total control")
            }
            if(n_affected_intervention != "" && error_codes.includes(n_affected_intervention) == false) {
              if(n_affected_intervention != null) {
                var n_total_intervention_id = "n_total_intervention-" + data_entry_id
                var n_total_intervention = null
                if(document.getElementById(n_total_intervention_id) != null) {
                  n_total_intervention = document.getElementById(n_total_intervention_id).value
                } else {
                  console.log("n total intervention not found! column: n total control")
                }
                if(n_total_intervention != "" && error_codes.includes(n_total_intervention) == false) {
                  if(n_total_intervention != null) {
                    var n_affected_control_id = "n_affected_control-" + data_entry_id
                    var n_affected_control = null
                    if(document.getElementById(n_affected_control_id) != null) {
                      n_affected_control = document.getElementById(n_affected_control_id).value
                    } else {
                      console.log("n affected control not found! column: n total control")
                    }
                    if(n_affected_control != "" && error_codes.includes(n_affected_control) == false) {
                      if(n_affected_control != null) {
                        var odds_ratio_id = "odds_ratio-" + data_entry_id
                        var odds_ratio = document.getElementById(odds_ratio_id).value
                        var value = parseFloat(n_affected_intervention) / (parseFloat(n_total_intervention) - parseFloat(n_affected_intervention))
                        var value2 = parseFloat(n_affected_control) / (parseFloat(event.target.value) - parseFloat(n_affected_control))
                        value = value / value2
                        value = value.toFixed(5)
                        var target = document.getElementById(odds_ratio_id)
                        if(odds_ratio_calc == true) {
                          target.value = value
                          this.stimulate('DataEntry#calculate', target.dataset['elementid'], target.dataset['column'], target.value, event.target.dataset['currentuserid'])
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else if(this.typeTarget.value == "Data Checking") {
      var id = event.target.id
      var id_array = id.split("-")
      var column = id_array[0]
      var data_entry_id = id_array[1]
      if(event.target.value != previousValue) {
        if(event.target.value != "") {
          //console.log(event.target.value)
          //console.log(event.target.defaultValue)
          //console.log(event.target.tagName)
          event.target.classList.remove("saved")
          event.target.classList.remove("certified")
          event.target.classList.add("discuss")
          this.stimulate('DataEntry#discuss', event.target.dataset['elementid'], event.target.dataset['column'], event.target.value, event.target.dataset['currentuserid'])
          event.target.value = previousValue
        }
      } else {
        //console.log(event.target.value)
        //event.target.classList.remove("saved")
        //event.target.classList.remove("discuss")
        //event.target.classList.add("certified")
        this.stimulate('DataEntry#certify', event.target.dataset['elementid'], event.target.dataset['column'], event.target.value, event.target.dataset['currentuserid'])
      }
    } else if(this.typeTarget.value == "Review") {
      event.target.value = previousValue
    }
  }

  filter () {
    this.stimulate('DataEntry#filter', event.target.value, event.target.dataset['column'], event.target.dataset['filters'])
  }

  entrytype () {
    var data_entry_types = document.getElementsByClassName("document.getElementById")
    var index = 0
    while (index < data_entry_types.length) { 
        data_entry_types[index].value = event.target.value
        index++
    }
    this.stimulate('DataEntry#entry_type', event.target.value)
  }
}