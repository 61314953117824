import ApplicationController from './application_controller.js'

export default class extends ApplicationController {

  run_search () {
  	console.log(event.target.value)
    this.stimulate('DataEntry#run_search', event.target.value)
  }

  add_drug () {
  	this.stimulate('DataEntry#add_drug', event.target.dataset['drugid'], event.target.dataset['clinicalunitid'], event.target.dataset['query'])
  }

}