import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {

  static targets = [ "processing" ]

  connect() {
  	//console.log("Yo!")
  	//var selected_row = document.getElementById("selected_data_table")
    //if(selected_row != null) {
  	// selected_row.scrollIntoView({block: "center"})
    //}
    StimulusReflex.register(this)
  }

  disable() {
    this.processingTarget.classList.remove("hidden")
    event.target.classList.add("hidden")
    var buttons = document.querySelectorAll(".btn")
    //console.log(buttons.length)
    buttons.forEach(button => button.classList.add("hidden"))
    //buttons.classList.add("hidden")
  }
  
}