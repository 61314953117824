import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "button" ]

  toggle() {
  	const dataEntryId = this.element.getAttribute("data-reviewer-report-entryId")
  	var className = dataEntryId + "-logs"
  	var logs = document.getElementsByClassName(className)
  	//console.log(logs)
  	Array.from(logs).forEach(log => log.classList.toggle("hidden"))
  	//console.log("Hello!")
  }
}